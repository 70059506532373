import React, { Component} from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Comments } from "../components/comments";

function withRouter(Component) {
    function ComponentWithRouter(props) {
      let params = useParams()
      return <Component {...props} params={params} />
    }
    return ComponentWithRouter
  }

class PostPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            post: [],
            featured: [],
            activeComments: 0,
            notFound: 0
        };
        
    }
    componentDidMount() {
        this.setState({
            seo_link : this.props.params.seo_link
        });
        this.fetchPost(this.state.seo_link);
        
    }
    
    static getDerivedStateFromProps(nextProps) {
            return {
              seo_link : nextProps.params.seo_link,
            }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
            if (prevProps.params.seo_link !== this.props.params.seo_link) {
                this.setState({
                    activeComments: 0
                });
                this.fetchPost(this.props.params.seo_link);
            }
    }


    fetchPost = seo_link => {
        this.setState({
            isLoaded: false
        });
        fetch("https://api.blog.programix.ct8.pl/api/post/" + seo_link)
            .then(res => res.json())
            .then(
                result => {
                    
                    if (result.data.content === undefined) {
                        this.setState({
                            isLoaded: true,
                            notFound: 1,
                            activeComments: 1
                        });
                    } else {
                        this.setState({
                            isLoaded: true,
                            post: result.data
                        });
                        this.fetchFeatured(result.data.id);
                    }
                },
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    };

    fetchFeatured = id => {
        fetch("https://api.blog.programix.ct8.pl/api/randomposts/" + id)
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        featured: result.data
                    });
                },
                error => {
                    this.setState({
                        featured: error
                    });
                }
            );
    };



    renderPost = () => {
        if (this.state.notFound === 1){
            return <p key="not-found">Nie ma takiego posta!</p>
        }else{
            return( <div className="post-content">
                <h1 key={"header" + this.state.post.name}>{this.state.post.name}</h1>
                {<div key={"content" + this.state.post.name} dangerouslySetInnerHTML={{ __html: this.state.post.content}}></div>}
            </div>);
        }
    }

    renderFeatured = () => {
        return this.state.featured.map(featured => (
            <div  key={featured.id} className="featured-post">
                <p><Link to={"/post/" + featured.seo_link}>{featured.name}</Link></p>
            </div>
        ));
    }

    showComments = (event) => {
        this.setState({
            activeComments: 1
        });
    } 

    renderComments = () => {
        if (this.state.activeComments === 0) {
            return <button className="showcomments" onClick={this.showComments}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
                        </svg>
                        Pokaż komentarze
                    </button>
        } else if (this.state.notFound === 1) {
            return <div></div>
        }else {
            return <Comments id={this.state.post.id}/>
        }
    }

    render() {
        if (this.state.error) {
            return <p key="error" className="info">Błąd! Nie można wczytać danych!</p>
        } else if (!this.state.isLoaded) {
            return <div className="loader"></div>
        } else {
            return(
                <div className="section-post">
                    <div className="container-post">
                    {this.renderPost()}
                        <div className="featured-posts">
                            <h3>Przeczytaj także:</h3>
                            {this.renderFeatured()}
                            <p key="home-p"><small key="home-small">Lub: <Link to={"/category/" + this.state.post.category}>posty z kategorii {this.state.post.category}</Link>, <Link to="/">wróć na stronę główną</Link></small></p>
                        </div>
                    </div>
                    {this.renderComments()}
                </div>
            );
        }
    }
}
const Post = withRouter(PostPage);
export {Post };