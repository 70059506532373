import React from "react";

function NotFound() {
    return (
        <div id="content">
            <center>Nie znaleziono!</center>
        </div>
    );
}

export {NotFound };