import './App.css';
import './fonts/Quicksand.ttf';
import './fonts/JosefinSans.ttf';
import './fonts/Belanosima.ttf';
import './comments.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PostListPage } from './pages/postsList';
import { Post } from './pages/post';
import { NotFound } from './pages/notFound';
import Changelog from './pages/changelog';
import React from 'react';
import Category from './pages/category';



function App(props) {
  React.useEffect(() => {
    const { appServiceWorker } = props;
    appServiceWorker.onInstalled(() =>
      console.log("appServiceWorker.onInstalled")
    );
    appServiceWorker.onUpdateFound(() => {
        console.log("Aktualizacja znaleziona!")
      }
    );
  }, [props]);
  return (
    <div id="page">
      <header>
          <h1>ProgramiX Blog</h1>
      </header>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PostListPage />} />
          <Route path="/post/:seo_link" element={<Post />} />
          <Route path="/category/:category" element={<Category />} />
          <Route path='/changelog' element={<Changelog />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <footer>
        <p className="footer">2023, ProgramiX Blog. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
