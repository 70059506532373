import React, {Component} from 'react';

class Changelog extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoaded: false,
            data: []
        }
    }

    componentDidMount() {
        fetch("https://api.blog.programix.ct8.pl/api/changelog")
         .then(res => res.json())
         .then(result => {
            this.setState({
                isLoaded: true,
                data: result
            });
         })
    }

    render() {
        if (!this.state.isLoaded) {
            return <div className="loader"></div>
        } else {
            return <div className="section-post">
                <div className="container-post">
                    <div className="post-content">
                        <h1>Dziennik zmian technicznych w aplikacji</h1>
                        <b>Wersja aplikacji front-endowej:</b> 1.0.0<br/>
                        <b>Wersja aplikacji back-endowej:</b> {this.state.data.version}<br/>
                        <b>Opis zmian:</b><br/>
                        <div dangerouslySetInnerHTML={{ __html: this.state.data.data}}></div>
                        <br/>
                        <i>Do poprawnego działania aplikacji wymagana jest stale aktualizowana przeglądarka oparta na projekcie Chromium (taki warunek spełnia np. Google Chrome, Microsoft Edge, Opera, Vivaldi) lub Firefox (i jego forki, jak Librewolf)</i><br/>
                        <i>Nie gwarantujemy poprawnego działania aplikacji przy użyciu innych przeglądarek bądź przy użyciu starszych wersji przeglądarek wspieranych. Nieaktualizowana przeglądarka może sprawić kłopoty w przyszłości. Problemy może sprawiać także Safari, tzw. Edge Legacy (Microsoft Edge w wersji nieopartej na Chromium, stara niebieska ikona) czy Android WebView.</i><br/>
                        <i>Aplikacja <b>NIE DZIAŁA</b> w żadnej wersji przeglądarki Internet Explorer.</i><br/>
                    </div>
                </div>
            </div>
        }
    }
}

export default Changelog;