import React, { Component} from "react";
import { Link, useParams } from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouter(props) {
      let params = useParams()
      return <Component {...props} params={params} />
    }
    return ComponentWithRouter
  }


class CategoryPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            posts: [],
            total_pages: 0,
            page: 1,
            category: ""
        };
    }
    fetchPosts = (id, category) => {
        fetch("https://api.blog.programix.ct8.pl/api/category/"+ category + "?page=" + id)
            .then(res => res.json())
            .then(
                result => {
                    this.setState({
                        isLoaded: true,
                        posts: result.data,
                        total_pages: result.last_page
                    });
                },
                error => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            );
    };
    componentDidMount() {
        this.setState({
            category: this.props.params.category
        });
        this.fetchPosts(this.state.page, this.props.params.category); 
    }
    static getDerivedStateFromProps(nextProps) {
        return {
          seo_link : nextProps.params.category,
        }
    }

    updatePage = id => {
        this.setState({page: id}, this.fetchPosts(id, this.state.category));
    };
    renderPagination = () => {
        let pagination = [];
        for (let i = 1; i<= this.state.total_pages; i++) {
            pagination.push(
                <li key={i} className={i === this.state.page ? "active page-item" : "page-item"} onClick={() => this.updatePage(i)} value={i}>
                    <button className="page-link">{i}</button>
                </li>
            );
        }
        return pagination;
    };
    renderList = () => {
        return this.state.posts.map(post => (
            <div className="post" key={post.name}>
                <h4><Link to={"/post/"+ post.seo_link}>{post.name}</Link></h4>
                <p className="desc">{post.desc}</p>
                <Link to={"/post/" + post.seo_link}><button type="button" className="enter-button">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 12l14 0"></path>
                        <path d="M13 18l6 -6"></path>
                        <path d="M13 6l6 6"></path>
                    </svg>
                </button></Link>
            </div>
        ));
    };
    render() {
        if (this.state.error) {
            return <p className="info">Błąd! Nie można wczytać danych!</p>;
        } else if (!this.state.isLoaded) {
            return <div className="loader"></div>
        } else {
            return (
            <div className="container-list">
            <div className="posts">
                <h3>Posty z kategorii</h3>
                <div className="posts-grid">
                    {this.renderList()}
                    
                </div>
                <ul className="pagination">
                    {this.renderPagination()}
                </ul>
            </div>
        </div>);
        }
    };
}
let CategoryProps = withRouter(CategoryPage);
export default CategoryProps;