import React, {Component} from 'react';
import {Formik, Form, Field} from 'formik';

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: 0,
            comments: [],
            commentSent: 0,
            commentError: 0
        }
    }

    componentDidMount() {
        this.getComments();
    }

    

    getComments = () => {
        fetch("https://api.blog.programix.ct8.pl/api/comments/" + this.props.id)
            .then(res => res.json())
            .then(response => {
                this.setState({
                    isLoaded: 1,
                    comments: response.data
                });
            });
    }

    renderComments = () => {
        let commentsarray = this.state.comments;
        if (commentsarray.length === 0) {
            return <div className="comment">
                        <p>Brak komentarzy!</p>
                    </div>
        } else {
            return this.state.comments.map(comment => (
                <div className="comment" key={comment.id}>
                    <img src="/person.png" alt="Person icon" />
                    <p>{comment.comment}</p>
                </div>
            ));
        }
    }

    renderAlert = () => {
        if (this.state.commentSent === 1) {
            return <div className="comment-info">
                Komentarz został wysłany do sprawdzenia.
            </div>
        } else if (this.state.commentError === 1) {
            return <div className="comment-info-error">
                Formularz sfałszowany!
            </div>
        }
    }

    sendComment = (values) => {
        if(values.accepted === 1) {
            this.setState({
                commentError: 1
            });
        }else{
            if (values.comment === "") {
                alert("Pusty komentarz? Takich nie przyjmujemy! Wpisz coś w pole komentarza.");
            } else {
                let commentBody = JSON.stringify(values);
                fetch("https://api.blog.programix.ct8.pl/api/comments/create/" + this.props.id, {
                    method: "POST",
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    body: commentBody
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        console.log(response);
                        if (response.status === "error") {
                            this.setState({
                                commentError: 1
                            });
                        } else {
                            this.setState({
                                commentSent: 1
                            });
                        }
                        setTimeout(this.forceUpdate(), 3000);
                    })
            }
        }
    }

    render() {
        if (this.state.isLoaded !== 0) {
            return <div className="comments-section">
                <h3>Komentarze</h3>
                    <Formik
                        initialValues={{
                            comment: "",
                            accepted: 0,
                            blocked: 0
                        }}
                        onSubmit={(values) => {this.sendComment(values)}}
                    >
                        <Form className='comment-editor'>
                            <Field as="textarea" id="comment" name="comment" maxlenght="300"/>
                           
                            <Field type="hidden" name="blocked" id="blocked"/>
                            <button className="sendcomment" type="submit" disabled={this.state.commentSent === 1}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 14l11 -11"></path>
                                    <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                </svg>
                            </button>
                        </Form>
                    </Formik>
                
                {this.renderAlert()}
                <i>Każdy komentarz ma limit 300 znaków. Przed opublikowaniem treść zostanie zweryfikowana ręcznie bądź automatycznie (przez filtr). Zastrzegam sobie prawo do blokady komentarza bądź edycji jego treści.</i><br/>
                <i>Proszę o przemyślaną treść komentarza, ponieważ z powodu braku możliwości jednoznacznej identyfikacji nadawcy nie przyjmuję wniosków o edycję bądź usunięcie komentarza (nie dotyczy skarg na czyjś komentarz z powodu np. treści nieetycznej czy niezgodnej z prawem)</i>
                <div className="comments-list">
                    {this.renderComments()}
                </div>
            </div>
        }else{
            return <div className="comments-section">
                <div className="loader"></div>
            </div>
        }

    }
}

export {Comments};